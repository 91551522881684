@import 'styles/variables';

.calendar-range {
  width: 207px;
  display: flex;
  height: 40px;

  .flatpickr-input {
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 0 10px;
    background-color: $color-white;
    border: 1px solid $color-grey2;
    border-radius: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $color-grey;
    text-align: center;

    &::-webKit-input-placeholder {
      text-align: left;
    }
    &:-moz-placeholder {
      text-align: left;
    }
    &::-moz-placeholder {
      text-align: left;
    }
    &:-ms-input-placeholder {
      text-align: left;
    }

    &:hover,
    &:focus {
      border-color: $color-grey;
    }
  }
}

.rangeMode {
  &.flatpickr-calendar {
    opacity: 0;
    display: none;
    text-align: center;
    visibility: hidden;
    padding: 20px;
    animation: none;
    direction: ltr;
    border: 0;
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    box-sizing: border-box;
    touch-action: manipulation;
    border-radius: 12px;
    background: $color-white;
    box-shadow: 0 4px 12px 0 rgb(0 0 0 / 15%);

    &.open {
      opacity: 1;
      max-height: 640px;
      visibility: visible;
      display: inline-block;
      z-index: 99999;
      animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
      margin-left: -75px;
      margin-top: 5px;
    }

    &:focus {
      outline: 0;
    }
  }

  .flatpickr-months {
    display: flex;
    position: relative;
    margin-bottom: 17px;

    .flatpickr-month {
      background: transparent;
      color: rgb(0 0 0 / 90%);
      fill: rgb(0 0 0 / 90%);
      height: 34px;
      line-height: 1;
      text-align: center;
      position: relative;
      user-select: none;
      overflow: hidden;
      flex: 1;
    }

    .flatpickr-prev-month,
    .flatpickr-next-month {
      user-select: none;
      text-decoration: none;
      cursor: pointer;
      position: absolute;
      top: -3px;
      height: 18px;
      padding: 10px;
      z-index: 3;
      color: rgb(0 0 0 / 90%);
      fill: rgb(0 0 0 / 90%);

      &.flatpickr-disabled {
        display: none;
      }

      svg {
        width: 14px;
        height: 14px;

        path {
          transition: fill 0.1s;
        }
      }

      &:hover {
        svg {
          fill: $color-blue;
        }
      }
    }

    .flatpickr-next-month {
      &.flatpickr-prev-month {
        left: 0;
        right: 0;
      }

      &.flatpickr-next-month {
        right: 0;
      }
    }
  }

  .numInputWrapper {
    position: relative;
    height: auto;

    input,
    span {
      display: inline-block;
    }

    input {
      width: 100%;

      &::-ms-clear {
        display: none;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
      }
    }

    span {
      position: absolute;
      right: 0;
      width: 14px;
      padding: 0 4px 0 2px;
      height: 50%;
      line-height: 50%;
      opacity: 0;
      cursor: pointer;
      border: 1px solid rgb(57 57 57 / 15%);
      box-sizing: border-box;

      &:hover {
        background: rgb(0 0 0 / 10%);
      }

      &:active {
        background: rgb(0 0 0 / 20%);
      }

      &::after {
        display: block;
        content: '';
        position: absolute;
      }

      &.arrowUp {
        top: 0;
        border-bottom: 0;

        &::after {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 4px solid rgb(57 57 57 / 60%);
          top: 26%;
        }
      }

      &.arrowDown {
        top: 50%;

        &::after {
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid rgb(57 57 57 / 60%);
          top: 40%;
        }
      }

      svg {
        width: inherit;
        height: auto;

        path {
          fill: rgb(0 0 0 / 50%);
        }
      }
    }

    &:hover {
      span {
        opacity: 1;
      }
    }
  }

  .flatpickr-current-month {
    font-size: 135%;
    font-weight: 300;
    color: inherit;
    position: absolute;
    width: 75%;
    left: 12.5%;
    line-height: 1;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(0, 0, 0);

    .numInputWrapper {
      width: 6ch;
      display: inline-block;

      span {
        &.arrowUp::after {
          border-bottom-color: rgb(0 0 0 / 90%);
        }

        &.arrowDown::after {
          border-top-color: rgb(0 0 0 / 90%);
        }
      }
    }

    input.cur-year {
      background: transparent;
      box-sizing: border-box;
      color: inherit;
      cursor: text;
      padding: 0 0 0 0.5ch;
      margin: 0;
      display: inline-block;
      font-size: inherit;
      font-family: inherit;
      font-weight: 300;
      line-height: inherit;
      height: auto;
      border: 0;
      border-radius: 0;
      vertical-align: initial;
      appearance: textfield;

      &:focus {
        outline: 0;
      }

      &[disabled] {
        font-size: 100%;
        color: rgb(0 0 0 / 50%);
        background: transparent;
        pointer-events: none;

        &:hover {
          font-size: 100%;
          color: rgb(0 0 0 / 50%);
          background: transparent;
          pointer-events: none;
        }
      }
    }

    .flatpickr-monthDropdown-months {
      background: transparent;
      border: none;
      border-radius: 0;
      box-sizing: border-box;
      color: inherit;
      cursor: pointer;
      font-size: inherit;
      font-family: inherit;
      font-weight: 300;
      height: auto;
      line-height: inherit;
      outline: none;
      position: relative;
      vertical-align: initial;
      width: auto;
      text-align: right;
      padding-right: 5px;
      appearance: none;

      &:focus,
      &:active {
        outline: none;
      }

      .flatpickr-monthDropdown-month {
        background-color: transparent;
        outline: none;
        padding: 0;
      }

      &::-ms-expand {
        display: none;
      }
    }
  }

  .flatpickr-weekdays {
    background: transparent;
    text-align: center;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    height: 28px;

    .flatpickr-weekdaycontainer {
      display: flex;
      flex: 1;
    }

    span.flatpickr-weekday {
      cursor: default;
      font-size: 90%;
      background: transparent;
      color: rgb(0 0 0 / 54%);
      line-height: 1;
      margin: 0;
      text-align: center;
      display: block;
      flex: 1;
      font-weight: bolder;
    }
  }

  .flatpickr-days {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;

    .dayContainer {
      padding: 1px 0 0;
      outline: 0;
      text-align: left;
      width: 307.875px;
      min-width: 307.875px;
      max-width: 307.875px;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      transform: translate3d(0, 0, 0);
      opacity: 1;

      .flatpickr-day {
        background: none;
        box-sizing: border-box;
        color: $color-dark;
        cursor: pointer;
        font-weight: 400;
        width: 14.2857143%;
        flex-basis: 14.2857143%;
        max-width: 39px;
        height: 39px;
        line-height: 39px;
        margin: 1px 0 0;
        display: inline-block;
        position: relative;
        justify-content: center;
        text-align: center;

        &.inRange,
        &.prevMonthDay.inRange,
        &.nextMonthDay.inRange,
        &.today.inRange,
        &.prevMonthDay.today.inRange,
        &.nextMonthDay.today.inRange,
        &:hover,
        &.prevMonthDay:hover,
        &.nextMonthDay:hover,
        &:focus,
        &.prevMonthDay:focus,
        &.nextMonthDay:focus {
          cursor: pointer;
          outline: 0;
          background: rgb(0 153 250 / 10%);
        }

        &:hover {
          border-radius: 50px;
        }

        &.selected,
        &.startRange,
        &.endRange,
        &.selected:focus,
        &.startRange:focus,
        &.endRange:focus,
        &.selected:hover,
        &.startRange:hover,
        &.endRange:hover,
        &.selected.prevMonthDay,
        &.startRange.prevMonthDay,
        &.endRange.prevMonthDay,
        &.selected.nextMonthDay,
        &.startRange.nextMonthDay,
        &.endRange.nextMonthDay {
          background: $color-blue;
          box-shadow: none;
          color: $color-white;
          border-color: $color-blue;
        }

        &.startRange.prevMonthDay,
        &.selected.startRange,
        &.startRange,
        &.selected.endRange,
        &.endRange,
        &.selected.startRange.endRange {
          border-radius: 50px;
        }

        &.selected.startRange::before,
        &.startRange::before {
          content: '';
          position: absolute;
          top: 0;
          left: 22px;
          width: 22px;
          height: 39px;
          background: rgb(0 153 250 / 10%);
          z-index: 0;
        }

        &.selected.endRange::before,
        &.endRange::before {
          content: '';
          position: absolute;
          top: 0;
          right: 17px;
          width: 22px;
          height: 39px;
          background: rgb(0 153 250 / 10%);
          z-index: 0;
        }

        &.inRange {
          border-radius: 0;
          box-shadow: 5px 0 0 rgb(0 153 250 / 10%);
        }

        &.prevMonthDay,
        &.nextMonthDay {
          color: rgb(57 57 57 / 30%);
          background: transparent;
          border-color: transparent;
          cursor: default;
        }

        &.hidden {
          visibility: hidden;
        }
      }
    }

    &:focus {
      outline: 0;
    }
  }

  .flatpickr-innerContainer {
    display: flex;
    box-sizing: border-box;
    overflow: hidden;

    .flatpickr-rContainer {
      display: inline-block;
      padding: 0;
      box-sizing: border-box;
    }
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.wrapper {
  position: relative;
  overflow: hidden;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgb(0 153 250 / 10%);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

@import 'styles/variables';

.wrapper {
  margin: 50px auto;
  height: 60px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;

  .loader {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: linear-gradient(to left, $color-blue 20%, $color-white 80%);
    position: relative;
    animation: load 1.4s infinite linear;
    transform: translateZ(0);

    &::after {
      background: $color-white;
      width: 85%;
      height: 85%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      inset: 0;
    }
  }
}

.content {
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  align-items: center;
  background-color: rgb(255 255 255 / 50%);
}

.process {
  margin: 0;
  height: 30px;

  .loader {
    width: 30px;
    height: 30px;
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

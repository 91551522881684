@import 'styles/variables';

.header {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
}

.searchWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.search {
  width: 300px;
}

.btnTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
}

.tHead,
.tRow {
  display: grid;
  grid-column-gap: 2px;

  > div {
    height: 48px;
    padding: 2px 0;
    cursor: pointer;
    text-align: center;
    border-radius: 4px 4px 0 0;
    background: rgb(0 153 250 / 10%);
    color: $color-blue;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;

    svg {
      display: block;
      margin: 0 auto;
      stroke: $color-blue;
      opacity: 0.5;
    }

    &:first-child {
      cursor: inherit;
      display: flex;
      text-align: left;
      align-items: center;
      padding-left: 20px;
    }

    &:hover:not(:first-child) {
      background: rgb(0 153 250 / 20%);

      svg {
        opacity: 1;
      }
    }
  }
}

.tRow {
  margin-top: 2px;

  > div {
    border-radius: 4px;
    background: $color-lightGrey;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-grey;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    svg {
      width: 24px;
      height: 24px;
      opacity: 1;
    }

    .new {
      display: flex;
      align-items: center;
      gap: 3px;
      color: $color-green;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;

      svg {
        path {
          stroke: $color-green;
        }
      }
    }

    .disabled {
      svg {
        path {
          stroke: $color-grey;
        }
      }
    }

    &:first-child {
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 10px;
      color: $color-dark;
      cursor: pointer;

      svg {
        margin: 0;
        opacity: 0.5;

        path {
          stroke: $color-grey;
        }
      }

      &:hover {
        background: rgb(0 153 250 / 20%);

        svg {
          opacity: 1;
        }
      }
    }

    &:hover.empty {
      background: $color-lightGrey;
      cursor: not-allowed;
    }
  }
}

.scroll {
  max-height: calc(100vh - 250px);
  overflow: hidden scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media all and (width >= $wideMode) {
  .search {
    width: 465px;
  }
}

@import 'styles/variables';

.wrapper {
  display: grid;
  grid-template-columns: $sidebarWidth 1fr;
  transition: grid-template-columns 100ms ease-in;

  &.hideSidebar {
    grid-template-columns: $sidebarWidthSm 1fr;
  }
}

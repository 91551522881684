@import 'styles/variables';

.header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 21px;
}

.search {
  width: 300px;
}

.btn {
  width: 170px;
}

.clear {
  color: $color-blue;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
}

.tHead,
.tRow {
  display: flex;
  justify-content: space-between;

  > div {
    width: 80px;
    min-height: 38px;
    padding: 7px 20px;
    display: flex;
    align-items: center;
    border-radius: 4px 4px 0 0;
    background: rgb(0 153 250 / 10%);
    color: $color-blue;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;

    &:nth-child(2) {
      width: 116px;
    }

    &:first-child {
      width: 498px;
    }
  }
}

.tHead {
  margin-top: 21px;
}

.tRow {
  margin-top: 2px;

  > div {
    border-radius: 4px;
    background: $color-lightGrey;
    justify-content: space-between;
    color: $color-dark;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }

    &:last-child {
      justify-content: center;
      cursor: pointer;

      &:hover {
        background: rgb(0 153 250 / 20%);
      }
    }
  }

  .hover {
    cursor: pointer;
  }
}

.arrowWrapper {
  padding: 0 10px;
}

.arrow {
  border: solid $color-grey;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  bottom: 2px;

  &.arrowUp {
    transform: rotate(-135deg);
    position: relative;
    top: 3px;
  }

  &.arrowDown {
    transform: rotate(45deg);
  }
}

.tRowSub {
  > div {
    background: rgb(0 153 250 / 10%);

    &:first-child {
      padding-left: 40px;
      width: 478px;
    }
  }
}

.scroll {
  max-height: calc(100vh - 250px);
  overflow: hidden scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media all and (width >= $wideMode) {
  .tHead,
  .tRow {
    > div {
      width: 160px;

      &:nth-child(2) {
        width: 286px;
      }

      &:first-child {
        width: 671px;
      }
    }
  }

  .tRowSub {
    > div {
      &:first-child {
        padding-left: 40px;
        width: 651px;
      }
    }
  }

  .search {
    width: 465px;
  }
}

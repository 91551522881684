$fontFamily: Hind, sans-serif;

// Colors
$color-black: #3e3232;
$color-white: #fff;
$color-dark: #090a0b;
$color-grey: #888b8d;
$color-grey2: #ebebec;
$color-lightGrey: #fafafa;
$color-lightGrey2: #f3f3f4;
$color-lightGrey3: #eaebee;
$color-lightGrey4: #d9d9d9;
$color-blue: #0099fa;
$color-darkBlue: #0069ff;
$color-darkBlue2: #09144a;
$color-lightBlue: #e5f5fe;
$color-green: #8acb00;
$color-greenLight: #f3fae5;
$color-purple: #a33ee8;
$color-orange: #ff4321;
$color-pink: #f0338c;
$color-red: #ff0321;
$color-yellow: #fdb10e;

// Wide width mode
$wideMode: 1736px;

$sidebarWidth: 255px;
$sidebarWidthSm: 92px;

@import 'styles/variables';

.root {
  position: fixed;
  max-width: calc(100% - $sidebarWidth);
  right: 0;
  bottom: 0;
  z-index: 2;
  height: 275px;
  box-sizing: border-box;

  &.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    width: 100%;
    padding-bottom: 40px;
  }

  &.sidebarHidden {
    max-width: calc(100% - $sidebarWidthSm);
  }
}

.mediaWrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;

  video, img {
    object-fit: none;
  }

  & > div {
    display: flex;
  }
}

.closeIcon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;

  path {
    fill: $color-dark;
  }
}

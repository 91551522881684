@import 'styles/variables';

.header {
  background-color: $color-lightGrey;
  padding-top: 16px;
}

.content {
  width: 940px;
  margin: 0 auto;
  position: relative;
}

.tabWrapper {
  display: flex;
}

.tab {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-transform: uppercase;
  padding: 16px 70px;
  color: $color-grey;
  cursor: pointer;

  &:hover {
    color: $color-blue;
  }
}

.active {
  color: $color-blue;
  font-weight: 500;
  border-radius: 12px 12px 0 0;
  background-color: $color-white;
}

@media all and (width >= $wideMode) {
  .content {
    width: 1441px;
  }
}
